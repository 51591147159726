import { Component, OnInit, Input, ViewContainerRef, ElementRef } from '@angular/core';
import { File } from '@app/core/models/file.model';
import { OrderReport } from '@app/modules/orders/models/order-report.model';
import { InboundOrdersService } from '@app/modules/orders/services/inbound-orders.service';
import { MakorOrderAPIService } from '@app/modules/orders/services/makor-order-api.service';
import { ColumnDef } from '@app/shared/components/custom-table/components/column-container/column';
import { ColumnElementParams } from '@app/shared/components/custom-table/components/column-container/column-element-params';
import { ColumnComponent } from '@app/shared/components/custom-table/components/column-container/column.component';
import { ColumnType } from '@app/shared/components/custom-table/components/column-container/column.type';

export class OrderReportSelectColumnDef extends ColumnDef {
  constructor(
    header: string,
    fieldName: string,
    params: { id: number | string },
    hidden: boolean = false
  ) {
    super(ColumnType.OrderReportSelect, header, fieldName, hidden, params);
  }
}

@Component({
  selector: 'order-report-select-column',
  template: `<div style="display:flex">
    <select
      (click)="loadList()"
      (change)="selectedReport($event)"
      class="report-select"
    >
      <option *ngFor="let o of options" [value]="o.id">{{ o.text }}</option>
    </select>
    <div style="width: 20px">
      <mat-icon
        class="text-primary download-report"
        (click)="downloadReport()"
        [style.visibility]="!downloading && selectedOption !== '' ? 'visible' : 'hidden'"
        >cloud_download</mat-icon
      >
      <mat-spinner diameter="20" *ngIf="downloading"></mat-spinner>
    </div>
  </div>`,
  styles: [
    '.report-select {display: flex; width: 200px} .download-report { cursor: pointer; height: 21px;margin-left:2px;  } .report-select { color:#757575; min-width: 215px; padding: 5px 5px; -webkit-appearance: none; -moz-appearance: none; appearance: none; background: url(/assets/img/select-arrow.svg) no-repeat 99% center #FFFFFF; outline: none;}',
  ],
  providers: [MakorOrderAPIService, InboundOrdersService],
})
export class OrderReportSelectColumnComponent
  implements ColumnComponent, OnInit
{
  @Input() params: ColumnElementParams;

  options: { id: string; text: string }[] = [];
  orderId: number = 0;
  loaded: boolean = false;
  downloading: boolean = false;
  selectedOption: string = '';
  tableElement : HTMLElement | null | undefined;
  constructor(
    private orderService: MakorOrderAPIService,
    private orderReportService: InboundOrdersService,
    private elRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.orderId = +this.params.data[this.params.params.id];
    this.setDefaultList();
    this.setParentNode();
  }
  setParentNode(){
    let nativeElement = this.elRef.nativeElement as HTMLElement | null | undefined;
    let times = 0;
    while(true){
      if(nativeElement?.parentElement?.nodeName === 'APP-CUSTOM-TABLE'){
        nativeElement = nativeElement.parentElement;
        break;
      }else{
        nativeElement = nativeElement?.parentElement;
      }
      times++;
      if(times >= 15){
        break;
      }
    }
    this.tableElement = nativeElement;
  }
  setDefaultList(orderReports: OrderReport[] = []) {
    this.options = [];
    if (orderReports.length === 0) {
      this.options.push({ id: '', text: 'Select report' });
      return;
    }

    this.options.push({ id: '', text: 'Select report' });
    this.options.push(
      ...orderReports.map((x) => ({ id: x.actualName, text: x.displayName }))
    );
  }

  setLoadingList() {
    this.options = [];
    this.options.push({ id: '', text: 'Loading...' });
  }

  loadList() {
    if (this.loaded) return;

    this.setLoadingList();
    this.orderReportService.getReportsList(this.orderId).subscribe((result) => {
      this.setDefaultList(result);
      this.loaded = true;
    });
  }

  selectedReport(obj: any) {
    this.selectedOption = obj.target.value;
    this.tableElement?.scrollTo(this.tableElement?.scrollWidth,0);
  }

  downloadReport() {
    this.downloading = true;
    this.orderService.getReport(this.orderId, this.selectedOption).subscribe(
      (result) => {
        this.downloading = false;
        result.fileName = this.options.find(e=>e.id === this.selectedOption)?.text || result.fileName;
        this.downloadFile(result);
      },
      (error) => {
        console.log('Error', error);
        this.downloading = false;
      }
    );
  }

  downloadFile(file: File) {
    fetch(`data:plain/text;base64,${file.content}`)
      .then((res) => res.blob())
      .then((docFile) => {
        const objectUrl = URL.createObjectURL(docFile);

        let a = document.createElement('a');
        a.setAttribute('href', objectUrl);
        a.setAttribute(
          'download',
          this.getFileName(`${file.fileName}_${this.orderId}`, file.ext)
        );
        a.click();
      });
  }

  getFileName(name: string, type: string): string {
    let fileName = name;
    let ext = type.indexOf('.') < 0 ? '.' + type : type;
    if (String(fileName).lastIndexOf('.') < 0) fileName += ext;

    return fileName;
  }
}
